<template>
  <div class="detail-index-bg">
    <div class="detail-index">
      <Crumbs/>
      <!-- <div class="detail-index-crumbs">
        <router-link to="/"> <span>首页</span></router-link>

        <span style="margin-right:0.1rem;margin-left:0.1rem">/</span>
        <span class="detail-index-crumbs-up">志愿服务</span> /
        <span class="detail-index-crumbs-this">{{ info.name }}</span>
      </div> -->
      <div class="detail-index-top">
        <div class="detail-index-top-content-headPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-top.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-content-footerPic">
          <img
            style="width: 100%; height: 100%"
            src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
            alt=""
          />
        </div>
        <div class="detail-index-top-left">
          <img style="width: 100%; height: 100%" :src="info.cover" alt="" />
        </div>
        <div class="detail-index-top-right">
          <div class="right-top">
          <div class="volunteer-detail-top-title">{{ info.name }}</div>
          <div class="volunteer-detail-top-des">
            <ul class="volunteer-detail-top-des-ul">
              <li class="volunteer-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_successor_intangible.png"
                  alt=""
                />
                <div class="volunteer-detail-top-des-ul-li-div">
                  <span>负责人：</span>{{ info.leader_name }}
                </div>
              </li>
              <li class="volunteer-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_top_phone_icon.png"
                  alt=""
                />
                <div class="volunteer-detail-top-des-ul-li-div">
                  <span>联系电话：</span>{{ info.leader_phone }}
                </div>
              </li>
              <li class="volunteer-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_people_volunteer.png"
                  alt=""
                />
                <div class="volunteer-detail-top-des-ul-li-div">
                  <span>团队人数：</span>{{ info.team_total }}
                </div>
              </li>
              <li class="volunteer-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_date_culture.png"
                  alt=""
                />
                <div class="volunteer-detail-top-des-ul-li-div">
                  <span>成立时间：</span>{{ info.regtime }}
                </div>
              </li>
              <!-- <li class="volunteer-detail-top-des-ul-li">
                <img
                  style="width: 0.24rem; height: 0.24rem; margin-right: 0.1rem"
                  src="https://image.bookgo.com.cn/webculture/jm/detail/detail_time_resource_t.png"
                  alt=""
                />
                <div class="volunteer-detail-top-des-ul-li-div">
                  <span>服务时长：</span>{{ info.service_time }}
                </div>
              </li> -->
            </ul>
          </div>
          </div>
            <div class="volunteer-detail-top-button" @click="handleJoin">申请志愿者</div>
        </div>
      </div>

      <div class="detail-index-bottom">
        <div class="detail-index-bottom-content">
          <div class="detail-index-top-content-footerPic">
            <img
              style="width: 100%; height: 100%"
              src="https://image.bookgo.com.cn/webculture/jm/border-bottom.png"
              alt=""
            />
          </div>
          <div class="detail-index-bottom-content-top">
            <div class="detail-index-bottom-content-top-title">团队详情</div>
          </div>

          <div class="detail-index-bottom-content-bottom">
            <div class="detail-index-bottom-content-bottom-box" v-if="info.summary">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  团队介绍
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div v-html="info.summary">{{ info.summary }}</div>
              </div>
            </div>
            <div class="detail-index-bottom-content-bottom-box" v-if="volunteer_activity_list&&volunteer_activity_list.length>0">
              <div class="detail-index-bottom-content-bottom-box-top">
                <div class="detail-index-bottom-content-bottom-box-top-icon">
                  <img
                    style="width: 100%; height: 100%"
                    src="https://image.bookgo.com.cn/webculture/jm/detail/detail_title_left_icon.png"
                    alt=""
                  />
                </div>
                <div class="detail-index-bottom-content-bottom-box-top-title">
                  组织的活动
                </div>
              </div>
              <div class="detail-index-bottom-content-bottom-box-bottom">
                <div
                  class="detail-index-bottom-content-bottom-box-bottom-li"
                  v-for="(item, index) in volunteer_activity_list"
                  :key="index"
                >
                  <div
                    class="detail-index-bottom-content-bottom-box-bottom-li-left"
                  >
                  <!-- 1活动已结束，2活动进行中，3报名已结束活动未开始，4预约(报名)中，5报名未开始 -->
                    <div
                      class="volunteer-detail-bottom-activity-ul-li-status volunteer-status1"
                      v-if="item.status == 1"
                    >
                      {{item.status_text}}
                    </div>
                    <div
                      class="volunteer-detail-bottom-activity-ul-li-status volunteer-status2"
                      v-else-if="item.status == 2"
                    >
                      {{item.status_text}}
                    </div>
                    <div
                      class="volunteer-detail-bottom-activity-ul-li-status volunteer-status3"
                      v-else-if="item.status == 3"
                    >
                      {{item.status_text}}
                    </div>
                    <div
                      class="volunteer-detail-bottom-activity-ul-li-status volunteer-status4"
                      v-else-if="item.status == 4"
                    >
                      {{item.status_text}}
                    </div>
                    <div
                      class="volunteer-detail-bottom-activity-ul-li-status volunteer-status5"
                      v-else-if="item.status == 5"
                    >
                      {{item.status_text}}
                    </div>
                    <div class="volunteer-detail-bottom-activity-ul-li-title" style="font-family:PingFangSCRegular;">
                      {{ item.active_time }} |
                      {{ item.title }}
                    </div>
                  </div>
                  <div class="volunteer-detail-bottom-activity-ul-li-right" @click="toActiveDetail(item)">
                      <div>查看详情</div>
                      <img
                        style="
                          width: 0.24rem;
                          height: 0.24rem;
                          display: block;
                          margin-left: 0.16rem;
                        "
                        src="https://image.bookgo.com.cn/webculture/jm/detail/detail_next_volunteer.png"
                        alt=""
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mydialog
      v-if="formDialogShow"
      :keyword="'扫码申请志愿者'"
      :isShow="formDialogShow"
      :src="detailSrc"
      @close="formDialogShow = false"
    />
  </div>
</template>

<script>
import {
  voluteerDetail,
  voluteerActiveList,
  genQRCode,
} from "@/api/common";
import moment from "moment";
import Crumbs from "@/views/components/crumbs";
import mydialog from "@/components/myDialog";
export default {
  components: { mydialog,Crumbs },
  data() {
    return {
      info: {},
      detailSrc: "",
      formDialogShow: false,
      popupTitle: "扫描二维码申请志愿者",
      volunteer_activity_list: [],
      detailType: "volunteer",
      volunteer_team_list: [],
      id: null,
      tid:"",
    };
  },
  created() {},
  mounted() {
      document.documentElement.scrollTop = 0;
      this.tid = this.$route.query.tid;
    this.getDetail();
    
  },
  watch: {
    $route(newV) {
      document.documentElement.scrollTop = 0;
      this.tid = newV.query.tid;
      this.getDetail()
    },
  },
  methods: {
    getDetail() {
      voluteerDetail({ team_id: this.tid }).then((res) => {
        if (res.code == 0) {
          const regex = new RegExp("<img", "gi");
          res.data.summary = res.data.summary
            ? res.data.summary.replace(
                regex,
                `<img style="max-width: 100%; height: auto;margin:0 auto"`
              )
            : "";
          this.info = res.data;
        }

        this.getActiveList();
      });
    },
    getActiveList() {
      var now = moment().unix();
      voluteerActiveList({
        volunteer_team_id: this.tid,
        aid: process.env.VUE_APP_AID,
        active_type:1,
      }).then((res) => {
        //console.info(res)
        if (res.code == 0) {
          let actList = res.data.datalist;
          // for (let i = 0; i < actList.length; i++) {
          //   let start_at = moment(
          //     actList[i].active_start_time,
          //     "YYYY年MM月DD日 hh:mm:ss"
          //   ).unix();
          //   let end_at = moment(
          //     actList[i].active_end_time,
          //     "YYYY年MM月DD日 hh:mm:ss"
          //   ).unix();
          //   let status;
          //   if (end_at < now) {
          //     status = 3; //已结束
          //   } else if (now < end_at && now > start_at) {
          //     status = 2; //进行中
          //   } else {
          //     status = 1; //预约中
          //   }
          //   actList[i].status = status;
          // }
          this.volunteer_activity_list = actList;
        }
      });
    },
    toActiveDetail(item){
        let routeUrl = this.$router.resolve({
          path: `/volunteerActive-default/detail`,
          query: { tid:item.id,id:this.$route.query.id,cid:this.$route.query.cid,column_id:this.$route.query.column_id },
        });
        window.open(routeUrl.href, "_blank");
    },
    handleClosed() {},
    handleJoin() {
      let params = {
        wxapp_name: "culture",
        page:
          "bookgo/culture/volunteer/team/teamDetail?id=" +
          this.tid +
          "&aid=" +
          process.env.VUE_APP_AID,
        scene: "",
        aid:process.env.VUE_APP_AID
      };
      this.formDialogShow = true;
      genQRCode(params).then((res) => {
        let pic = res.data;
        this.detailSrc = pic;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/detail.scss";
@import "../../assets/scss/volunteerTeam_default_detail.scss";
@import "../../assets/scss/dialog.scss";
</style>
